import React from "react";
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import CardText from "react-bootstrap/CardText";
import Button from "react-bootstrap/Button";
import CardTitle from "react-bootstrap/CardTitle";
import { Cart4 } from "react-bootstrap-icons";

const PreviewCoursecard = ({ course, handleViewClick, handleBuyClick, ownedCheck }) => (
    <Card className="mx-4 my-4" style={{ width: '18rem', marginBottom: '10px', backgroundColor: "#363636", minHeight: '266px' }}>
      <CardImg variant="top" src={`http://localhost:5000/${course.idcourses}.png`} />
      <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '24px', color: "white", textShadow: "1px 1px 1px black" }}>  {course.idcourses}  </div>
      <Card.Body style={{ alignContent: "flex-end"}}>
        <CardTitle style={{color: "white"}}>{course.title}</CardTitle>
        <CardText style={{color: "white"}}>{course.coursetext}</CardText>
        <div className="" style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
          {/* <Button variant="primary" onClick={handleViewClick}>VIEW</Button> */}
          <Button variant="outline-light"  onClick={handleViewClick} class="btn btn-outline-light btn-lg px-4" style={{"--bs-btn-color": "#AD974F","--bs-btn-hover-bg": "#363636", "--bs-btn-border-color":"#AD974F","--bs-btn-hover-color": "white",}} >View</Button>
          {/* <Button variant="outline-success" onClick={handleBuyClick}><Cart4 className='cart-icon'/> Add to cart</Button> */}
          {ownedCheck && <Button variant="outline-success" onClick={handleBuyClick}>
            <div className="d-flex align-items-center">
              <Cart4 className='cart-icon'/>
              <span style={{marginLeft: "1vh", color: "white"}}>{course.price + " Kč"}</span>
            </div>
          </Button>}
        </div>
      </Card.Body>
    </Card>
  );
  
  export default PreviewCoursecard;