import React, { useState, useEffect } from 'react';

const ScrollCounter = ({ number, duration = 2000 }) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    let start = Date.now();
    const increment = number / (duration / 10); // Calculate increment per 10ms

    const animate = () => {
      const elapsedTime = Date.now() - start;
      const progress = Math.min(elapsedTime / duration, 1);
      const current = Math.floor(progress * number);

      setCurrentNumber(current);

      if (elapsedTime < duration) {
        window.requestAnimationFrame(animate);
      }
    };

    animate();

    return () => window.cancelAnimationFrame(animate); // Cleanup on unmount
  }, [number, duration]);

  return (
    <span className="counter">{currentNumber.toLocaleString()}</span>
  );
};

export default ScrollCounter;
