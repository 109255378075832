import  { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

function AdminOffcanvas({handleShow}) {
    const [showOffCanvas, setShowOffCanvas] = useState(handleShow);
    
    const handleClose = () => setShowOffCanvas(false);
    // const toggleShow = () => setShow((s) => !s);
    
    return (
      <>
        {/* <Button variant="primary" onClick={toggleShow} className="me-2">
          test offcanvas
        </Button> */}
        <Offcanvas show={handleShow} onHide={handleClose} scroll={true} backdrop={false} placement='end'>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            Some text as placeholder. In real life you can have the elements you
            have chosen. Like, text, images, lists, etc.
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  export default AdminOffcanvas