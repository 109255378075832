import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import * as Icon from "react-bootstrap-icons"
import 'bootstrap/dist/css/bootstrap.min.css';
import { ArrowReturnRight } from 'react-bootstrap-icons';
import Button from "react-bootstrap/Button";
import { useJwt } from "react-jwt";
import { useEffect, useState } from 'react';
import "../../index.css";
import NavDropdown from 'react-bootstrap/NavDropdown';

 const MainNavBar =  () => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('email') !== null);

  const renderUser = () => {
    if(isLoggedIn){
      const deleteLocalStorage = () => {
        if (window.confirm("Are you sure you want to clear all local storage data?")) {
          localStorage.clear();
          setIsLoggedIn(false);
        }
      }

      const email = localStorage.getItem("email");
      const exp = localStorage.getItem("exp");
      const expTimeDate = new Date(exp * 1000);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC' // Add time zone if needed
      };
        const formattedTime = expTimeDate.toLocaleDateString('en-US', options); 
      


      return(
        <Nav>
          {localStorage.admin && (<Button className='complementary-gold' variant="outline-dark" href='/Admin'>Správa kurzů</Button>)}
          


          {/* <Nav.Item href="#profile">{email}</Nav.Item> */}
          {/* <Nav.Item href="#profile">expires in: {formattedTime}</Nav.Item> */}
          
          {/* <Button className="complementary-gold" variant='outline-dark' onClick={deleteLocalStorage}>logout</Button> */}
          {/* <Icon.PersonCircle/> */}
          <NavDropdown
            id="nav-dropdown-dark-example"
            title={<img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/logo_white.png" width="25" height="25" alt="logo"></img>}
            menuVariant="dark"
            align="end"
          >
            {localStorage.admin && (<NavDropdown.ItemText style={{ color: "#DABF66" }} as="span" >Logged in as admin</NavDropdown.ItemText> )}
            <NavDropdown.Item href="#profile">{email}</NavDropdown.Item>
            <NavDropdown.ItemText as="span" href="#action/3.2">token expires in: {formattedTime}</NavDropdown.ItemText>
            
            <NavDropdown.Divider />
            <NavDropdown.Item  onClick={deleteLocalStorage}>
              LOG OUT
            </NavDropdown.Item>
          </NavDropdown>
          
        </Nav>
      )
    }
    else{
      return (
        <Nav>
          <Button href='/Login' className='px-4 complementary-gold' variant='outline-dark'> Log In</Button>
          {/* <Button className='complementary-gold'>test</Button>
          <div  style={{width: "100px", height: "100px", }}></div> */}
        </Nav>
      )
    }
  }

  return (
    <>
    <Navbar variant="dark" expand="lg" style={{ backgroundColor: '#363636' }} className="">
      <Container>
        <Navbar.Brand className='text-white' href="/">EduFinInvest</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">About</Nav.Link>
            <Nav.Link href="/Courses">Courses</Nav.Link>
            <Nav.Link href="#link">Contacts</Nav.Link>
          </Nav>
          {/* <Nav>
            <Button href='/Login' variant='outline-light'> Log In</Button>
          </Nav> */}
          {renderUser()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default MainNavBar;