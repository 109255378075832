import React from "react";
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import CardText from "react-bootstrap/CardText";
import Button from "react-bootstrap/Button";
import CardTitle from "react-bootstrap/CardTitle";

const CourseCard = ({ course, handleEditClick, handleDeleteClick }) => (
    <Card className="m-4" style={{backgroundColor: "#363636", color: "white", width: '18rem' }}>
      <CardImg variant="top" src={`http://localhost:5000/${course.idcourses}.png`} onError={(e) => {e.target.src="https://placehold.co/600x400/EEE/31343C"}} />
      <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '24px', color: "white", textShadow: "1px 1px 1px black" }}>  {course.idcourses}  </div>
      <Card.Body>
        <CardTitle>{course.title}</CardTitle>
        <CardText>{course.coursetext}</CardText>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Button variant="primary" onClick={handleEditClick}>Edit</Button>
          <Button variant="outline-danger" onClick={handleDeleteClick}>delete</Button>
        </div>
      </Card.Body>
      <Card.Footer className="">cena: <p className="text-success">{course.price} Kč</p></Card.Footer>
    </Card>
  );
  
  export default CourseCard;