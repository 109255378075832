import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import "../../index.css"


export default function Hero() {
  return (
<>
    <div className="container col-xxl-8 px-4">
      <div className="row flex-lg-row-reverse align-items-center g-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <h1 className="display-5 fw-bold lh-1 mb-3 text-white">Education, Finance & Investment</h1>
          <p className="lead text-white">
          Lorem ipsum dolor sit amet, consectetuer
 adipiscing elit. Fusce tellus odio, dapibus id fermentum
 quis, suscipit id erat. Nunc dapibus tortor vel mi 
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
            <Button href='/Courses' className='complementary-gold btn-lg px-4' variant="outline-dark" >Explore courses</Button>
            {/* <button className="btn btn-outline-secondary btn-lg px-4">Default</button> */}
            <button type="button" class="btn btn-outline-light btn-lg px-4" style={{"--bs-btn-color": "#AD974F","--bs-btn-hover-bg": "#363636", "--bs-btn-border-color":"#AD974F","--bs-btn-hover-color": "white",}} >Learn More</button>
          </div>
        </div>
        <div className="col-lg-6">
        <img
            src="./graphics/man.png" // Replace with your image path
            alt="Bootstrap Themes"
            className="d-block mx-lg-auto img-fluid"
            width="700"
            height="500"
            loading="lazy"
          />
        </div>
        <Card body style={{ backgroundColor: "#363636", color: "white", bottom: "3em" }}>
          <h2>Pavel Švéda</h2>
          <p>
            Morbi tempus iaculis urna id volutpat. Odio eu feugiat pretium nibh. Sem et tortor consequat id. Pretium viverra suspendisse potenti nullam ac tortor vitae purus. Dictum fusce ut placerat orci nulla alaka bakala lala.
          </p>
          
        </Card>
      </div>
    </div>

      
        {/* <Col md={6}>
          <Image src="./graphics/man.png" alt="Hero Image" />
          </Col>
          <Col md={6}>
          <div>
            <h1 style={{color: "white"}}>Education, Finance & Investment</h1>
            <p style={{color: "white"}}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce
              tellus odio, dapibus id fermentum quis, suscipit id erat. Nunc
              dapibus tortor vel mi.
            </p>
            <Button variant="outline-dark" className='complementary-gold'>Explore Courses</Button>
            <Button variant="outline-dark" className='complementary-gold'>Learn More</Button>  
          </div>
          </Col> */}
      </> 
  )
}
