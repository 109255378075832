import React, { useState, useEffect } from 'react';
import { Alert, Card, CardBody, CardTitle, Form, FormControl, Button } from 'react-bootstrap';
import MainNavBar from './Components/MainNavbar';
import {Container, Row, Col } from 'react-bootstrap';
import PreviewCoursecard from "./Components/PreviewCourseCard";
import Modal from "react-bootstrap/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './Components/CheckoutForm';
import Footer from "./Components/Footer";
import  Dropdown  from 'react-bootstrap/Dropdown';
import { Cart4 } from 'react-bootstrap-icons';
import { Link, UseNavigate, useNavigate } from "react-router-dom";


const stripePromise = loadStripe('pk_test_51Ost3TE0wWw2vPjheSJ3pCPYcONdEA9gBJZrmuzo6a98HHAloZJZOrRvKQtWSWPkuCtqoKfxnfYxyCSwNyKLPHgP00jbhv3tfd');

const Courses = ({ initialTitle }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [alertMessage, setAlertMessage] = useState('');
  const [courses, setCourses] = useState([]);
  const [viewCourseModal, setViewCourseModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [cart, setCart] = useState([]);
  const [loggedIn, setLoggedIn] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [ownedCourses, setOwnedCourses] = useState();

  useEffect(() => {
    const fetchCourses = async () => {
        try {
            const response = await fetch("/api/listallcourses");
            const data = await response.json();
            setCourses(data.courses);
            // setCourseCount(data.courseCount);
        }
        catch(error){
            console.error("error fetching courses", error);
        }
    };
    fetchCourses();

}, []);

// useEffect(() => {
//   const fetchUserCourses = async () => {
//     try{
//       const jwt = localStorage.getItem("jwt");
//       const res = await fetch("/api/checkUserCourses?" + new URLSearchParams({ jwt: jwt }));

//       const response = await res.json();
//       setOwnedCourses(response);
//       console.log(response);
//     }
//     catch(error){
//       console.error(error);
//     }
//   }
//   fetchUserCourses();
// }, [courses])




useEffect(() => {
  populateCartFromLocal();
}, []);

const populateCartFromLocal = () => {
  const local = localStorage.getItem("myCart");
  if(local){
    const myCart = JSON.parse(local);
    if(myCart.length > 0)
      setCart(myCart);
    else {
      const emptyCart = [];
      localStorage.setItem("myCart", JSON.stringify(emptyCart));
    }
  }
}

const handleBuyClick = (course) => {
  const isCourseInCart = cart.some(courseExists => {
    return courseExists.idcourses === course.idcourses;
  });
  if(!isCourseInCart){
    const newCart = [...cart, course];
    setCart(newCart)
    localStorage.setItem("myCart", JSON.stringify(newCart));
  }
}

const handleRemoveItem = (itemToRemove) => {
  const filterCart = cart.filter((item) => item !== itemToRemove);
  setCart(filterCart);
  const local = localStorage.getItem("myCart");
  if(local){
    let localToFilter = JSON.parse(local);
    const localFilter = localToFilter.filter((item) => item.idcourses !== itemToRemove.idcourses);
    localStorage.setItem("myCart", JSON.stringify(localFilter));
  }
}

const navigate = useNavigate();
//TODO handle prompt login/registration if not logged in 
const handleCheckout = () => {
  const usertoken = localStorage.getItem("jwt");
  if(!usertoken){
    setLoggedIn(false);
    setShowAlert(true);
    return;
  }
  setLoggedIn(true);
  navigate("/embedded", { state: cart });
}

const handleViewClick = (courses) => {
  setSelectedCourse(courses);
  setViewCourseModal(true);
  return;
}

const handleViewNavigateClick = (courses) => {
  const navigateurl = `/course/${courses.idcourses}`;
  navigate(navigateurl, { state: { CourseId: courses.idcourses } });
}

const checkOwnership = (course) => {
  var isOwned = false
  if(ownedCourses){
    isOwned = ownedCourses.includes(course.idcourses)
  }
  return isOwned;
}

  return (
    <>
      
      <MainNavBar/>
      <Container fluid className="primary-background pattern d-flex justify-content-center p-4">
      {!loggedIn && showAlert && <Alert className='' variant='danger' dismissible onClose={() => setShowAlert(false)} style={{position: 'fixed', zIndex: "10", width: "100%"}}>You must be logged in</Alert>}
        <Col>
          <Row>
            <Container className='d-flex justify-content-center align-items-center'>
              <h1 style={{color: 'white'}}>Kurzy</h1>
            </Container>
          </Row>
          <Row>
            <Col lg={{span: 10, offset: 1}} className=''>
              <Row>
                  <Container fluid className='d-flex flex-wrap justify-content-center'>
                  {courses.map((course) => (
                    // const isOwned = false
                    // if(ownedCourses){
                    //   isOwned = ownedCourses.includes(course.idcourses)
                    // }
                    <>
                    <PreviewCoursecard key={course.idcourses} course={course} handleViewClick={() => handleViewNavigateClick(course)} handleBuyClick={() => handleBuyClick(course)} ownedCheck={Array.isArray(ownedCourses) && ownedCourses.includes(course.idcourses)}/>
                    </>
                  ))}
                  </Container>
                

                {selectedCourse != null && <Modal
                  size="lg"
                  show={viewCourseModal}
                  onHide={() => setViewCourseModal(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    
                    <Modal.Title id="example-modal-sizes-title-lg">
                      {selectedCourse.title}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedCourse.coursetext}
                  </Modal.Body>
                </Modal>
                }
                
              </Row>
            </Col>
          </Row>
        </Col>
          <Dropdown style={{position: 'fixed', bottom: "10vh", right: "2vh"}}>
            {/* <Dropdown.Toggle variant='outline-light' class="btn btn-outline-light btn-lg px-4" style={{"--bs-btn-color": "#AD974F","--bs-btn-hover-bg": "#363636", "--bs-btn-border-color":"white","--bs-btn-hover-color": "white",}}> */}
            <Dropdown.Toggle variant='outline-dark' className='complementary-gold' style={{width: "7vh", height: "7vh"}}>
            <Cart4 className='cart-icon'/>
            <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2" style={{ width: '3vh', height: '3vh' }}>{cart.length === 0 ? "" : cart.length}</span>
            </Dropdown.Toggle>

            {/* TODO FIX RENDER IF THERE ARE TOO MANY PRODUCTS IN CART - ITS UNUSABLE */}
            {cart.length > 0 &&<Dropdown.Menu>
              {cart.map((item) => (
                <>
                <Dropdown.ItemText>
                  <Button onClick={() => handleRemoveItem(item)} className='btn m-2' style={{"height":"","--bs-btn-color": "black", "--bs-btn-bg": "#ffffff","--bs-btn-border-color": "red", "--bs-btn-hover-color": "#fff","--bs-btn-hover-bg": "red"}}>odebrat</Button>
                  {item.title}<hr class="hr" />{item.price}
                </Dropdown.ItemText>
                <Dropdown.Divider/>
                </>
              ))}
              <Dropdown.Item>
                <Button onClick={handleCheckout}>
                <p className=''>CHECKOUT</p>
                </Button>
              </Dropdown.Item>
              
            </Dropdown.Menu>}
          </Dropdown>
    </Container>
    <Footer/>
    </>
  );
};

export default Courses;
