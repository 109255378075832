import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
    <footer className="bg-dark text-light py-3">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            © 2024 All rights reserved.
          </Col>
          <Col xs={12} md={6} className="text-md-right">
            <a className='white-link' href="#">About Us</a> | {' '}
            <a className='white-link' href="#">Contact</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;