import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "./Components/MainNavbar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { decodeToken, isExpired } from "react-jwt";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./Components/LoadingComponent";
import "../index.css";

const Login = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [formData, setFormData] = useState({
      email: "",
      password: "",
  });
  const navigate = useNavigate();

  const HandleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try
    {
      const response = await fetch('/api/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        //success response
        setAlertVariant("success");
        setShowAlert(true);
        const responseJson = await response.json();
        setAlertTitle(responseJson.msg + ' ');
        console.log('Form submission successful:'); //testing 

        const decodedToken = decodeToken(responseJson.token);
        const expiredToken = isExpired(responseJson.token);

        //testing
        console.log("id: ", decodedToken.id, "email: ", decodedToken.email, "is expired? : ", expiredToken);
        
        localStorage.setItem("jwt", responseJson.token);
        localStorage.setItem("id", decodedToken.id);
        localStorage.setItem("email", decodedToken.email);
        localStorage.setItem("expired", expiredToken);
        localStorage.setItem("exp", decodedToken.exp);
        if(decodedToken.admin === true){
          localStorage.setItem("admin", decodedToken.admin);
        }
        
        setTimeout(() => {  
          navigate("/");
        }, 2000);
        //redirect
        
      }
      else if(response.status === 401){
          setAlertVariant("danger");
          setShowAlert(true);
          const responseJson = await response.json();
          setAlertTitle(responseJson.msg);
      }
      else if(response.status === 400){
        setAlertVariant("danger");
        setShowAlert(true);
        const responseJson = await response.json();
        setAlertTitle(responseJson.msg);
      }
    } catch (error) {
      setAlertVariant("danger");
      setShowAlert(true);
      console.error('Error submitting form:', error);
      setAlertTitle("Something went wrong ", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
        ...formData,
        [name]: value,
    });
  };

  return(
    <>
      <Navbar/>
      <Container fluid className="primary-background pattern">
      <Container className="d-flex justify-content-center align-items-center vh-100 text-white">
      <Row>
        <Col sm={12} md={{ size: 6, offset: 0 }}>
          <h1>Sign in</h1>
          <Form className="border rounded p-3 "  onSubmit={HandleSubmit}>
            {showAlert && (
            <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
              <Alert.Heading>{alertTitle}</Alert.Heading>
              
              {alertTitle.endsWith(' ') && <LoadingComponent />}
              
            </Alert>
            )}
            <Form.Group controlId='emailGroup'>
                      <Form.Label>email address</Form.Label>
                      <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            pattern='^\S+@\S+\.\S+$'
                            autoComplete="email"
                          />
                    </Form.Group>
                    <Form.Group controlId='password'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              minLength={6}
                              required
                          />
                    </Form.Group>
              <Form.Text className="">
                <a className="white-link" href="#">Forgot password?</a>
              </Form.Text> 
              <br></br>
              <Form.Text className="text-muted">
                <a className="white-link" href="/Register">Don't have an account yet?</a>
              </Form.Text>
              <br></br>
            <Button variant="" className="btn btn-outline-dark btn-lg px-4" style={{borderColor: "#AD974F", color: "#DABF66"}} type="submit">
              Sign in
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </Container>
        </>
    )
}

export default Login;