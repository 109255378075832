import React from 'react';
import AdminNavbar from './Components/AdminNavbar';
import { createRef } from 'react';
import Card from "react-bootstrap/card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Routes, Route, Link, Navigate, json } from 'react-router';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AdminOffcanvas from "./Components/AdminOffcanvas";
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import FormControl from "react-bootstrap/FormControl"
import EditableCard from './Components/EditableCard';
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import Image from 'react-bootstrap/Image';
import Col from "react-bootstrap/Col";

import InputGroup from "react-bootstrap/InputGroup";



function Admin({admin}) {
    const [courses, setCourses] = useState([]);
    const [courseCount, setCourseCount] = useState(0);
    const [show, setShow] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [editableTitle, setEditableTitle] = useState();
    const [editableText, setEditableText] = useState();
    const [editablePrice, setEditablePrice] = useState();
    const [editableCourseChapters, setEditableCourseChapters] = useState([]);
    const [videos, setVideos] = useState([]);
    const [videoID, setVideoID] = useState([]);


    const [alertMessage, setAlertMessage] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    

    
    // useEffect for fetching courses, resets on server response when editing or deleting course
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/api/listallcourses");
                const data = await response.json();
                setCourses(data.courses);
                setCourseCount(data.courseCount);
            }
            catch(error){
                console.error("error fetching courses", error);
            }
        };
        fetchData();
    }, [alertMessage]);


    //preview image useEffect
    //TODO global variable for url or change after production mode
    
    useEffect(() => {
        //TODO clear preview url after closing offcanvas
        if(!selectedImage){
                // setPreviewUrl(null); 
                if(selectedCourse){
                    setPreviewUrl(`http://localhost:5000/${selectedCourse.idcourses}.png`);
                }
                return;
            }
        
        const objectUrl = URL.createObjectURL(selectedImage);
        setPreviewUrl(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedImage, selectedCourse]);

    //event listener for image preview
    //TODO image override error
    const onSelectFile = (event) => {
        if(!event.target.files || event.target.files === 0){
            setSelectedImage(undefined);
            return;
        }
        setSelectedImage(event.target.files[0]);
    }

    //event listener for image upload

    //TODO might need to cleanup video array so its not being uploaded many times over each other -??? test this
    const onSelectVideo = (event) => {
        const newVid = event.target.files[0];
        setVideos([...videos, newVid]);
    }

    const handleClose = () => {
        //TODO check unsaved changes before dialog
        if(window.confirm("are you sure you want to close?")){
            setShow(false);
            setIsEditing(false);
        }
        
    }
    const handleShow = () => {
        setShow(true);
        setIsEditing(true);
        setAlertMessage('');
    }
  
    if(admin === false || admin === undefined){
        return <Navigate to={"/"} replace/>
    }

    //opening offcanvas
    const handleEditClick = (course) => {
        setShow(true);
        setIsEditing(true);
        setAlertMessage('');
        setSelectedCourse(course);
        setEditableTitle(course.title);
        setEditableText(course.coursetext);
        setEditablePrice(course.price);
        loadChapters(course.idcourses);
    };

    //load chapters
    const loadChapters = async (id) => {
        try{
            const response = await fetch("/api/listChapters?" + new URLSearchParams({ courseid: id }));
            const data = await response.json();
            setEditableCourseChapters(data.chapters);
            console.log(editableCourseChapters);
        }
        catch(error){
            console.log(error);
        }
    };
  

    //deleting course
    const handleDeleteClick = async (course) => {
        try{
            if (window.confirm("Are you sure you want to delete course: "+ course.title+" ?")) {  
                const data = {
                    title: course.title,
                    courseText: course.coursetext,
                    courseid: course.idcourses
                }
                const response = await fetch("/api/deletecourse", {
                    method: "POST",
                    headers: { "Content-Type": "application/json"},
                    body: JSON.stringify(data)
                });
                const resjson = await response.json();
                setAlertMessage(resjson);
            }
        }
        catch(error){
            console.error("error while deleting course", error);
        }
    }

    //saving edited course
    const handleSaveClick = async () => {
        var message = "";
        try{
            //old post without image
            // const data = {
            //     selectedCourseId: selectedCourse.idcourses,
            //     title: editableTitle,
            //     courseText: editableText
            // }
        
            // const response = await fetch("/api/savecourse", {
            //     method: "POST",
            //     headers: { "Content-Type": "application/json"},
            //     body: JSON.stringify(data)
            // });

            const data = new FormData();
            //TODO correct constructing chapters, when creating send without ids, when altering send ids
            data.append('selectedCourseId', selectedCourse.idcourses);
            data.append('title', editableTitle);
            data.append('courseText', editableText);
            data.append('price', editablePrice);
            data.append('previewImage', selectedImage);
            data.append('chapters', JSON.stringify(editableCourseChapters));
            data.append('videoID', videoID);
            videos.forEach((videoFile, index) => {
                data.append(`courseVideos`, videoFile);
            });

            const response = await fetch("/api/savecourse", {
                method: "POST",
                body: data
            });

            const resJson = await response.json();
            setAlertMessage(resJson.msg);
            }
        catch(error){
            setAlertMessage("error while saving", error.message);
        }
    };
  

    //TODO video upload
    const fileInput = createRef();
    const onSubmit = async (e) =>
    {
        e.preventDefault();
        const formData = new FormData();
        formData.set("video", fileInput.current.files[0]);

        try{
            const response = await fetch("/addVideo",{
                method: "POST",
                body: formData
            });

            const parsedResponse = await response.json();
            if(response.ok){
                alert("file uploaded");
            }
            else{
                console.error("failed to upload file");
            }
        }
        catch(e){
            console.error(e.message)
        }
    }

    //add new course onclick
    const createNewCourseClick = () => {
        const lastCourse = courses[courseCount-1];
        
        setCourses((previousCourses) => {
            const newCourse = {
                idcourses: (lastCourse.idcourses+1),
                title: "",
                coursetext: ""
            };
            return [...previousCourses, newCourse];
        });
    }; 

    const createNewChapterClick = () => {
        setEditableCourseChapters((previousChapters) => {
            const newChapter = {
                //TODO check how this affects creating new chapter, after all it should only care about course id
                // chapterid: (editableCourseChapters.length + 1),
                chaptertitle: "",
                chapterdesc: "",
                CourseIdcourses: selectedCourse.idcourses
            };
            return [...previousChapters, newChapter];
        })
    }

    const deleteChapterClick = () => {
        if(window.confirm("are you sure you want to delete chapter?")) {
            const updatedChapters = editableCourseChapters.slice(0, -1);
            setEditableCourseChapters(updatedChapters);
        }
    }


    const renderChapters = () => {
        return(
        editableCourseChapters.map((chapter, index) => (
            <Card className='primary-background m-1'>
                <Card.Body className="">
                    <Card.Title>{index+1 + ". "}
                        {editableCourseChapters.length - 1 === index && <Button variant='danger' style={{float: 'right'}} onClick={deleteChapterClick}>delete</Button>}
                    </Card.Title>
                    <Card.Text>
                        <Form.Label>Název kapitoly</Form.Label>
                        <Form.Control
                            type="text"
                            value={chapter.chaptertitle}
                            onChange={(e) => {
                                setEditableCourseChapters(
                                    editableCourseChapters.map((item) => item.chapterid === chapter.chapterid ? {...item, chaptertitle: e.target.value } : item)
                                )
                              }}
                        />
                        <Form.Label>Popis kapitoly</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={chapter.chapterdesc}
                            onChange={(e) => {
                                setEditableCourseChapters(
                                    editableCourseChapters.map((item) => item.chapterid === chapter.chapterid ? {...item, chapterdesc: e.target.value } : item)
                                )
                              }}
                        />                        
                        <Form.Label>video</Form.Label>
                        <FormControl type='file' accept='video/*'
                            onChange={(event) => {        
                                const newVid = event.target.files[0];
                                setVideos([...videos, newVid]);
                                setVideoID([...videoID, chapter.chapterid]);
                                }
                            }
                        />
                        {/* <FormControl
                            type="file"
                            accept="video/*"
                            onChange={(event) => {
                                const newVid = event.target.files[0];
                                setVideos((prevVideos) => {
                                  const updatedVideos = prevVideos.slice(); // Create a copy of the array
                                  const foundIndex = prevVideos.findIndex((video) => video.chapterId === chapter.chapterid);
                                  if (foundIndex !== -1) {
                                    updatedVideos[foundIndex] = { ...updatedVideos[foundIndex], video: newVid };
                                  }
                                  return updatedVideos;
                                });
                              }}
                        /> */}
                    </Card.Text>
                </Card.Body>
            </Card>
        ))
        )
    }


    //offcanvas render
    const renderOffcanvasContent = () => {
        if (!selectedCourse) return null; 
        return (
            <>
                {alertMessage && <Alert variant="success">{alertMessage}</Alert>}
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{selectedCourse.idcourses}. {selectedCourse.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Form>
                    <Form.Group controlId='formImagePreview'>
                        {/* TODO preview image */}
                        <Form.Label>Náhled kurzu</Form.Label>
                        <FormControl type='file' accept='image/*' onChange={onSelectFile}/>
                        {previewUrl && (<Image src={previewUrl} onError={(e) => {e.target.src="https://placehold.co/600x400/EEE/31343C"}} alt='selected image preview' fluid/>)}
                        {/* <Image src={previewUrl} alt='selected image preview' fluid/> */}
                    </Form.Group>
                    <Form.Group controlId="formBasicTitle">
                    <Form.Label>Course Title</Form.Label>
                    <Form.Control
                        type="text"
                        value={editableTitle}
                        onChange={(e) => setEditableTitle(e.target.value)}
                    />
                    </Form.Group>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Popis kurzu</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={editableText}
                            onChange={(e) => setEditableText(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicPrice'>
                        <Form.Label>Cena</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Kč</InputGroup.Text>
                            <Form.Control
                                type="number"
                                min={0}
                                rows={3}
                                value={editablePrice}
                                onChange={(e) => setEditablePrice(e.target.value)}
                            />
                            <InputGroup.Text>.00</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>



                    {/* CHAPTERS */}
                    <Form.Group controlId='formNewCourseChapter'>                
                    
                    {renderChapters()}

                    {/* {editableCourseChapters.map((chapter) => ( 
                        <Card className='primary-background m-1'>
                            <Card.Body className="">
                                <Card.Title>{chapter.chapterid + ". " + chapter.chaptertitle}</Card.Title>
                                <Card.Text>
                                    <Form.Label>Název kapitoly</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={chapter.chaptertitle}
                                        onChange={(e) => setEditableTitle(e.target.value)}
                                    />
                                    <Form.Label>Popis kapitoly</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={chapter.chapterdesc}
                                        onChange={(e) => setEditableText(e.target.value)}
                                    />                        
                                    <Form.Label>video</Form.Label>
                                    <FormControl type='file' accept='image/*' onChange={onSelectFile}/>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))} */}


                        {/* <Card className='primary-background m-1'>
                            <Card.Body className="">
                                <Card.Title>Kapitola #1</Card.Title>
                                <Card.Text>
                                    <Form.Label>Název kapitoly</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value="Kapitola #1"
                                        onChange={(e) => setEditableTitle(e.target.value)}
                                    />
                                    <Form.Label>Popis kapitoly</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value="První kapitola popis"
                                        onChange={(e) => setEditableText(e.target.value)}
                                    />                        
                                    <Form.Label>video</Form.Label>
                                    <FormControl type='file' accept='image/*' onChange={onSelectFile}/>
                                </Card.Text>
                            </Card.Body>
                        </Card> */}
                        
                        {/* add new chapter card */}
                        <Card className='primary-background m-1'>
                            <Card.Body className="d-flex justify-content-center align-items-center">
                                <Button variant="link" className="plus-button" onClick={createNewChapterClick}>
                                    <Icon.PlusCircle size={80} color="currentColor" /> {/* Adjust size as needed */}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Form.Group>

                </Form>
                </Offcanvas.Body>
                <Button variant="primary" onClick={handleSaveClick}>
                    Save
                </Button>
            </>
        );
    };

    return (
    <>
    <AdminNavbar/>  
        <Container className='primary-background' fluid >
            <Col lg={{span: 10, offset: 1}}>
                <Row>
                    <h1 style={{color: "white"}}>Přehled kurzů</h1>
                </Row>
                <Row className=''>
                    {courses.map((course) => (
                        <EditableCard key={course.idcourses} course={course} handleEditClick={() => handleEditClick(course)} handleDeleteClick={() => handleDeleteClick(course)} />
                    ))}
                    <Card className='m-4' style={{ width: '18rem'}}>
                    <Card.Body className="d-flex justify-content-center align-items-center">
                        <Button variant="link" className="plus-button" onClick={createNewCourseClick}>
                        <Icon.PlusCircle size={80} color="currentColor" /> {/* Adjust size as needed */}
                        </Button>
                    </Card.Body>
                    </Card>
                </Row>
            </Col>
        </Container>
        
        {/* offcanvas logic */}
        <Offcanvas show={show} onHide={handleClose} scroll={false} backdrop="static" placement='end'>
            {renderOffcanvasContent()}
            {/* {alertMessage && <Alert variant="success">{alertMessage}</Alert>}
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                
                {isEditing ? (
                        <FormControl type="text" value={selectedCourse.title}  onChange={handleChangeTitle} />
                    ) : (
                        selectedCourse.title
                    )}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                    {isEditing ? (
                        <FormControl type="text" value={courseText} onChange={handleChangePreviewText} />
                    ) : (
                        title
                    )}
                Some text as placeholder. In real life you can have the elements you
                have chosen. Like, text, images, lists, etc.
                <form onSubmit={onSubmit}>
                    <input type='file' name='video' ref={fileInput}/>
                    <input type='submit' value="Submit"/>
                </form>
            </Offcanvas.Body>
            {isEditing && (
                    <Button size="sm" variant="success" onClick={handleSaveClick}>
                        Save
                    </Button>
                    )} */}
        </Offcanvas>
    </>
  )
}

export default Admin