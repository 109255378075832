import React from 'react';
import "../../index.css";

const TypingIndicator = () => {
  return (
    <>
    <p>redirecting</p>
    <div className="typing-indicator">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
    </>
  );
};

export default TypingIndicator;