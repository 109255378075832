import React, { useEffect, useState, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./Pages/Login";
import Home from "./Pages/Homescreen";
import Admin from "./Pages/Admin";
import Test from "./Pages/Test";
import Register from "./Pages/Register";
import Courses from "./Pages/Courses";
import Course from "./Pages/Course";
import EmbeddedForm  from "./Pages/Components/EmbeddedForm";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';


const stripePromise = loadStripe("pk_test_51Ost3TE0wWw2vPjheSJ3pCPYcONdEA9gBJZrmuzo6a98HHAloZJZOrRvKQtWSWPkuCtqoKfxnfYxyCSwNyKLPHgP00jbhv3tfd");

const CheckoutForm = () => {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch("/create-checkout-session", {
      method: "POST"
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    )
  }

  return null;
}

function App(){
  const [backendData, setBackendData] = useState([{}]);
  var adminPrivilage = false;
  adminPrivilage = localStorage.admin;

  // useEffect(() => {
  //   fetch("/api").then(
  //     response => response.json()
  //   ).then(
  //     data => {
  //       setBackendData(data)
  //     }
  //   )
  // }, []);
  
  return(
    
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Test" element={<> <Test/></>}/>
        <Route path="/checkout" element={<> <Test/></>}/>
        <Route path="/Courses" element={<Courses/>}/>
        <Route path="/Login" element={<Login/>}/>
        <Route path="/Admin" element={<Admin admin={adminPrivilage}/>}/>
        <Route path="/Register" element={<Register/>}/>
        <Route path="/Course" element={<Course/>}/>
        <Route path="/Course/:CourseId" element={<Course />} />
        <Route path="/embedded" element={<EmbeddedForm />} />
        <Route path="/return" element={<Return />} />
      </Routes>
    </BrowserRouter>

      {/* {(typeof backendData.users === "undefined") ? (
        <p>loading</p>
      ) : (
        backendData.users.map((user, i) => (
          <p key={i}>{user}</p>
        ))
      )} */}
    </>
  )
}

export default App;