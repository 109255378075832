import React from 'react'
import MainNavBar from './Components/MainNavbar';
import Plyr from 'plyr-react';
import Container  from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navbar } from 'react-bootstrap';
import "../index.css";
import Footer from './Components/Footer';
import { ArrowLeft } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

// const plyrProps = {
//     source: undefined,
//     options: undefined, 
// };

const Course = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoUrlBuffer, setVideoUrlBuffer] = useState([]);
  //TODO load chapters so it can work with ids
  const [currentChapter, setCurrentChapter] = useState(1);
  const [chapterList, setChapterList] = useState();
  const { CourseId } = useParams();
  const userId = localStorage.getItem("jwt");

//TODO check and redirect if user token doesnt match with course
//TODO create a buffer of loaded videos if theyve already clicked
  useEffect(() => {
    try{
      const urlLoaded = videoUrlBuffer.some(video => video.chapter === currentChapter);
      if(!urlLoaded){
        fetchVideo(CourseId, userId, currentChapter);
        fetchChapters();
      }
      else{
        setVideoUrl(videoUrlBuffer.find(video => video.chapter === currentChapter)?.videoSrc)
      }
    }
    catch(error){
      console.log(error);
    }
  }, [currentChapter]);

  const fetchVideo = async (CourseId, userId, currentChapter) => {
    const data = {
      courseId: CourseId,
      userId: userId,
      chapter: currentChapter
    }
    const response =  await fetch('/api/viewcourse', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    if(response.status === 200){
      //TODO success render course
      const test = response;
      console.log(test);
      const blob = await response.blob();
      const videoSrc = URL.createObjectURL(blob);
      setVideoUrl(videoSrc);
      const newUrlObject = {videoSrc: videoSrc, chapter: currentChapter};
      setVideoUrlBuffer(prevVideoUrlBuffer => [...prevVideoUrlBuffer, newUrlObject]); 
    }
    else if(response.status === 403){ 
      //TODO user does not own the course, render buy prompt
  
    }
    else if(response.status === 401){
      //TODO not logged in
      window.alert("you need to be logged in");
    }
  }

  const fetchChapters = async () => {
    const response = await fetch("/api/listChapters?" + new URLSearchParams({ courseid: CourseId }));
    const data = await response.json();
    setChapterList(data.chapters);
    
  };

  return (
    <>
    <MainNavBar/>
    <Container fluid className='primary-background pattern'>
      <Row>
        <Col md={{span: 2}} className='secondary-background'>
          <Container className='d-flex'>
            <Link to={"/courses"}>
              <Button className='d-flex justify-content-center' variant='outline-light'>
                <ArrowLeft size={18} />
              </Button>
            </Link>
          </Container>
          <Container className='d-flex p-2 justify-content-center'>
            <h3 className='text-white'>
              Kapitoly
            </h3>
          </Container>
          <ListGroup className='list-group-flush'>
            {chapterList && chapterList.map((item, index) => (
              <ListGroup.Item action className="" active={currentChapter === item.chapterid} onClick={() => {setCurrentChapter(item.chapterid)}} style={{"--bs-list-group-color": "white", "--bs-list-group-bg": "#363636", "--bs-list-group-action-hover-bg": "#363636", "--bs-list-group-action-hover-color": "#DABF66", "--bs-list-group-action-active-bg": "#545454"}} variant='dark' >{index+1+"." + " " + item.chaptertitle}</ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col>
        <Container className="justify-content-center align-items-center p-4">
              <Container className='d-flex p-4 justify-content-center'>
                
                <h2 className='text-white'>
                  {chapterList && chapterList[currentChapter-1].chaptertitle}
                </h2>
                
              </Container>
              <p className='text-white'>{chapterList && chapterList[currentChapter-1].chapterdesc}</p>
              {/* <Plyr style={{ '--plyr-color-main': '#DABF66' }} {...plyrProps}/> */}
              <Plyr
                style={{ '--plyr-color-main': '#DABF66'}}
                source={{
                  type: 'video',
                  sources: [
                    {
                      src: videoUrl,
                      type: 'video/mp4'
                    }
                  ]
                }}
              />
              <Container className='d-flex p-4 justify-content-center'>
                <h2 className='text-white'>
                  NADPIS
                </h2>
                
              </Container>
              <p className='text-white'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorem explicabo repellendus voluptates laboriosam doloribus in eum excepturi velit voluptas dolorum sint quaerat a veritatis ea deleniti facilis, perspiciatis reprehenderit blanditiis?</p>
        </Container>
        </Col>
      </Row>
      </Container>
      <Footer/>
    </>
  )
}

export default Course;