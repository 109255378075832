import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "./Components/MainNavbar";
import Hero from "./Components/Hero";
import Card from "react-bootstrap/card";
import Footer from "./Components/Footer";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import "../index.css";
import ScrollCounter from "./Components/ScrollCounter";
import { Button } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { FileEarmarkLock } from "react-bootstrap-icons";

const plyrProps = {
    source: undefined,
    options: undefined, 
};

export default function Home(){

    return(
        <>
            <Navbar/>
            {/* <Container fluid className="primary-background">
                <Hero/> 
                
                    <Card body style={{ backgroundColor: "#363636", color: "white" }}>
                    <h2>Pavel Švéda</h2>
                    <p>
                        Morbi tempus iaculis urna id volutpat. Odio eu feugiat pretium nibh. Sem et tortor consequat id. Pretium viverra suspendisse potenti nullam ac tortor vitae purus. Dictum fusce ut placerat orci nulla alaka bakala lala.
                    </p>
                    </Card>
                
            </Container> */}
            <Container fluid className="primary-background pattern">
                <Hero />
                <Container className="justify-content-center align-items-center p-4 col-lg-7 col-md-8 col-sm-12">
                    
                        <Plyr  style={{ '--plyr-color-main': '#DABF66' }} {...plyrProps}/>
                </Container>
                <Row className="justify-content-center">
                    <Col lg={4}>
                        <Card body bsPrefix="card-bg: secondary-background" className="text-white rounded p-3 my-2">                        
                            {/* <Container className=""> */}
                            <Row>
                                <Col>
                                <Stack className="align-items-center">
                                    <p className="text-center">počet kurzů</p>
                                    <div className='d-inline-flex gold-circle justify-content-center align-items-center'>
                                        <h3><ScrollCounter number={110} duration={5000} />+</h3>
                                    </div>
                                </Stack>
                                </Col>
                                <Col>
                                <Stack className="align-items-center">
                                    
                                    <p className="text-center">počet účastníků</p>
                                    <div className='d-inline-flex gold-circle justify-content-center align-items-center'>
                                        <h3><ScrollCounter number={60} duration={5000} />+</h3>
                                    </div>
                                </Stack>
                                </Col>
                                <Col>
                                <Stack className="align-items-center">
                                    <p className="text-center">placeholder</p>
                                    <div className='d-inline-flex gold-circle justify-content-center align-items-center'>
                                        <h3><ScrollCounter number={40} duration={5000} />+</h3>
                                    </div>
                                </Stack>
                                </Col>
                            </Row>
                            {/* </Container> */}
                        </Card>
                    </Col>
                </Row>
                
                <Container className="d-flex justify-content-center p-4">
                    <Row>
                        <Col>
                        <Card className="mx-4 my-4" style={{ width: '18rem', marginBottom: '10px', backgroundColor: "#363636", minHeight: '266px' }}>
                            <Card.Img variant="top" src="https://placehold.co/650x200" />
                            <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '24px', color: "white", textShadow: "1px 1px 1px black" }}>  1 </div>
                            <Card.Body style={{ alignContent: "flex-end"}}>
                                <Card.Title style={{color: "white"}}>test 1</Card.Title>
                                <Card.Text style={{color: "white"}}>test1</Card.Text>
                                <div className="" style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                                {/* <Button variant="primary" onClick={handleViewClick}>VIEW</Button> */}
                                <Button variant="outline-light" class="btn btn-outline-light btn-lg px-4" style={{"--bs-btn-color": "#AD974F","--bs-btn-hover-bg": "#363636", "--bs-btn-border-color":"#AD974F","--bs-btn-hover-color": "white",}} >View</Button>
                                <Button variant="outline-success">Purchase</Button>
                                </div>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col>
                        
                        <Card className="mx-4 my-4" style={{ width: '18rem', marginBottom: '10px', backgroundColor: "#363636", minHeight: '266px' }}>
                            
                            <Card.Img variant="top" src="https://placehold.co/650x200" />
                            <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '24px', color: "white", textShadow: "1px 1px 1px black" }}>  1 </div>
                            <Card.Body style={{ alignContent: "flex-end"}}>
                                <Card.Title style={{color: "white"}}>test 1</Card.Title>
                                <Card.Text style={{color: "white"}}>test1</Card.Text>
                                <div className="" style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                                {/* <Button variant="primary" onClick={handleViewClick}>VIEW</Button> */}
                                <FileEarmarkLock color="#DABF66"/>
                                </div>
                            </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Footer/>
        </>
    )
}