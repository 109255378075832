import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import * as Icon from "react-bootstrap-icons";


function AdminNavbar() {
  return (
    <>
    <Navbar expand="lg" style={{backgroundColor: "#363636"}}>
      <Container>
        <Navbar.Brand style={{color: "white"}} href="/">EduFinInvest  admin</Navbar.Brand>
        <Nav>
            <Nav.Link href='/Login' className='text-white'><Icon.PersonCircle/></Nav.Link>
          </Nav>
      </Container>
    </Navbar>
    <Navbar expand="lg" className="secondary-background">
      <Container>
          <Nav className="me-auto">
            <Nav.Link className='text-white' href="/admin">show all courses</Nav.Link>
            <Nav.Link className='text-white' href="#link">settings</Nav.Link>
         </Nav>
      </Container>
    </Navbar>
    </>
  )
}

export default AdminNavbar;