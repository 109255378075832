import React, { useState } from 'react';
import {Col, Row, Container, Form, Button} from "react-bootstrap";
import Navbar from "./Components/MainNavbar";
import Alert from 'react-bootstrap/Alert';

function Register() {
    const [validated, set_Validated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });

    const submitFn = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
                set_Validated(false);
            
            event.stopPropagation();
        }
        else {
            set_Validated(true);
            try 
            {
              const response = await fetch('/api/register', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(formData),
              });
              if (response.status === 200) {
                  setAlertVariant("success");
                  setShowAlert(true);
                  setAlertTitle("Registration success!");
                  console.log('Form submission successful:'); //testing  
              }
              else if(response.status === 409){
                  setAlertVariant("danger");
                  setShowAlert(true);
                  setAlertTitle("email already exists");
              }
            } catch (error) {
              setAlertVariant("danger");
              setShowAlert(true);
              setAlertTitle("Something went wrong ", error);
              console.error('Error submitting form:', error);
            }
        }
    };
  const chngFn = (event) => {
      const { name, value } = event.target;
      setFormData({
          ...formData,
          [name]: value,
      });
  };

  return (
    <>
        <Navbar/>

        <Container className="d-flex justify-content-center align-items-center vh-100">
            <Row>
                <Col sm={12} md={{ size: 6, offset: 0 }}>
                <h1>Sign Up</h1>
                <Form className="border border-primary rounded p-3" validated={validated} onSubmit={submitFn}>
                {showAlert && (
                  <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>{alertTitle}</Alert.Heading>
                    <p>
                      
                    </p>
                  </Alert>
                )}
                    <Form.Group controlId='emailGroup'>
                      <Form.Label>email address</Form.Label>
                      <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={chngFn}
                            required
                            pattern='^\S+@\S+\.\S+$'
                            isInvalid={validated && !/^\S+@\S+\.\S+$/.test(formData.email)}
                            autoComplete='email'
                          />
                      <Form.Control.Feedback type='invalid'>
                        please enter a valid email address
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='password'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={chngFn}
                              minLength={6}
                              required
                              isInvalid={
                                  validated && formData.password.length < 6
                              }
                          />
                      <Form.Control.Feedback>
                        Password must be at least 6 characters long
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='confirmPassword'>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                              type="password"
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={chngFn}
                              minLength={6}
                              required
                              pattern={formData.password}
                              isInvalid={
                                  validated &&
                                  formData.confirmPassword !== formData.password
                              }
                          />
                      <Form.Control.Feedback type='invalid'>
                        Passwords don't match
                      </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button variant="primary" type="submit">
                    Sign up
                    </Button>
                </Form>
                </Col>
            </Row>
        </Container>
    </>
  )
}

// const App = () => {
//     const [validated, set_Validated] = useState(false);
//     const [form_Data, set_Form_Data] = useState({
//         pass: "",
//         confimPass: "",
//         email: "",
//     });
//     const submitFn = async (event) => {
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             event.preventDefault();
//             event.stopPropagation();
//         }
//         else {
//             set_Validated(true);
    
//             try {
//             const response = await fetch('/api/submit-form', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify(form_Data),
//             });
//             if (!response.ok) {
//                 throw new Error(`Error submitting form: ${response.statusText}`);
//             }
//             const data = await response.json();
//             console.log('Form submission successful:', data);
//             } catch (error) {
//             console.error('Error submitting form:', error);
//             }
//         }
//     };
//     const chngFn = (event) => {
//         const { name, value } = event.target;
//         set_Form_Data({
//             ...form_Data,
//             [name]: value,
//         });
//     };
//     return (
//         <Container className="mt-5">
//             <Row>
//                 <Col
//                     md={{
//                         span: 6,
//                         offset: 3,
//                     }}
//                 >
//                     <h1
//                         style={{
//                             color: "green",
//                         }}
//                     >
//                         GeeksforGeeks
//                     </h1>
//                     <h3>React Bootstrap Form Validation</h3>
//                     <Form noValidate validated={validated} onSubmit={submitFn}>
                        
//                         <Form.Group controlId="password">
//                             <Form.Label>Password</Form.Label>
//                             <Form.Control
//                                 type="password"
//                                 name="pass"
//                                 value={form_Data.pass}
//                                 onChange={chngFn}
//                                 minLength={6}
//                                 required
//                                 isInvalid={
//                                     validated && form_Data.pass.length < 6
//                                 }
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 Password must be at least 6 characters long.
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                         <Form.Group controlId="confirmPassword">
//                             <Form.Label>Confirm Password</Form.Label>
//                             <Form.Control
//                                 type="password"
//                                 name="confimPass"
//                                 value={form_Data.confimPass}
//                                 onChange={chngFn}
//                                 minLength={6}
//                                 required
//                                 pattern={form_Data.pass}
//                                 isInvalid={
//                                     validated &&
//                                     form_Data.confimPass !== form_Data.pass
//                                 }
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 Passwords do not match.
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                         <Form.Group controlId="email">
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 name="email"
//                                 value={form_Data.email}
//                                 onChange={chngFn}
//                                 required
//                                 isInvalid={
//                                     // validated &&
//                                     // !/^\S+@\S+\.\S+$/.test(form_Data.email)
//                                     validated && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form_Data.email)
//                                 }
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 Please enter a valid email address.
//                             </Form.Control.Feedback>
//                         </Form.Group>
                        
//                         <Button type="submit">Submit</Button>
//                     </Form>
//                 </Col>
//             </Row>
//         </Container>
//     );
//   };
  
export default Register;